import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const loanRulesSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    fetchLoanRulesInit(state) {
      return {
        ...state,
        loanRulesLoading: true,
        loanRulesError: undefined,
        loanRulesSuccess: undefined,
        loanRulesFail: undefined,
      }
    },

    fetchLoanRulesSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
        loanRulesLoading: false,
        loanRulesError: undefined,
        loanRulesSuccess: true,
        loanRulesFail: false,
      }
    },

    fetchLoanRulesFail(state) {
      return {
        ...state,
        loanRulesLoading: false,
        loanRulesError: undefined,
        loanRulesSuccess: false,
        loanRulesFail: true,
      }
    },

    fetchLoanRulesError(state, action: PayloadAction<any>) {
      return {
        ...state,
        loanRulesLoading: false,
        loanRulesError: action.payload,
        loanRulesSuccess: false,
        loanRulesFail: false,
      }
    },

    setLoanRules(state) {
      return state
    },

    deleteLoanRules() {
      return initialState
    },
  },
})

export const {
  fetchLoanRulesInit,
  fetchLoanRulesSuccess,
  fetchLoanRulesFail,
  fetchLoanRulesError,
  deleteLoanRules,
} = loanRulesSlice.actions

export default loanRulesSlice.reducer
