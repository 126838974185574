import { ILoanRulesResponse } from '@services/loan/intefaces'

export const nameState = 'loanRules'

export interface ILoanRulesState extends ILoanRulesResponse {
  loanRulesLoading: boolean
  loanRulesError?: Error
  loanRulesSuccess?: boolean
}

export const initialState: ILoanRulesState = {
  loanRulesLoading: false,
  loanRulesError: undefined,
  loanRulesSuccess: undefined,
  erros: [],
  details: {
    hasLoanRequest: false,
    hasActiveLoanContract: false,
    hasActiveLoanContractPendingOfSignature: false,
    account: [{ bank: '', bankNumber: '', accountNumber: '' }],
    loanTypes: [
      {
        loanCodeType: '',
        maxInstallmentsQuantity: 0,
        maxAmount: '',
        maxInstallmentsAmount: '',
        minAmount: '',
        minInstallmentsAmount: '',
      },
    ],
    loans: [
      {
        id: '',
        userDocument: '',
        foundationId: '',
        loanExternalId: '',
        amount: 0,
        amountWithTax: 0,
        installmentsQuantity: 0,
        installmentsAmount: 0,
        status: '',
        acceptedTerms: [],
        bank: 0,
        agency: 0,
        agencyDigit: '',
        account: 0,
        accountDigit: '',
        concessionLocation: '',
        concessionDate: '',
        typeLoan: null,
        netValue: 0,
        iof: 0,
        admRate: 0,
        firstInstallmentDate: '',
        monthlyRate: 0,
        annualRate: 0,
        monthlyCet: 0,
        annualCet: 0,
        prevLoanContractedBalance: null,
        prevLoanDebitBalance: null,
        prevLoanChosenDeadline: null,
        prevLoanPaidInstallments: null,
        prevLoanRemainingInstallments: null,
        prevLoanValueInstallments: null,
        loanPartnerId: '',
        createdAt: '',
        updatedAt: '',
      },
    ],
  },
}
