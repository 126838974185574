import { IPoliticallyExposedPersonResponse } from '@resources/types/services/politicallyExposedPerson'

export const nameState = 'politicallyExposedPerson'

export interface IPoliticallyExposedPersonState
  extends IPoliticallyExposedPersonResponse {
  [x: string]: any
  pepLoading: boolean
  pepError?: Error
  pepSuccess?: boolean
}

export const initialState: IPoliticallyExposedPersonState = {
  createdDate: '',
  endDate: '',
  isPep: false,
  pepId: 0,
  personId: 0,
  startDate: '',
  roleId: 0,
  roleName: '',
  showOnLogon: false,

  pepLoading: true,
  pepError: undefined,
  pepSuccess: undefined,
}
